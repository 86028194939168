.subsite {
    .header {
        box-shadow: 1px 1px 20px rgba(48, 58, 114, 0.05);
    }
    &__content {
        padding-top: 100px;
        padding-bottom: 100px;
        @include mq($until: md) {
            padding-top: 60px;
            padding-bottom: 60px;
            }
        h1 {
            margin-bottom: 80px;
        }
    }

    &--cruise {
        .subsite__content {
            @include mq($until: md) {
                .row {
                    flex-direction: column-reverse;
                    .col-left {
                        margin-bottom: 0;
                    }
                    .col-right img {
                        max-width: 500px;
                        margin: 0 auto;
                        display: block;
                        margin-bottom: 20px;
                        width: 100%;
                    }
                }
            }
            h2 {
                strong {
                    font-weight: 700;
                    color: var(--paletteColor2);
                }
            }
            .time {
                color: var(--paletteColor2);
                font-weight: 600;
            }
            .reservation {
                margin-top: 20px;
            }
            ol {
                list-style: none;
                counter-reset: numeric-counter;
                margin-left: 0;
                li {
                    list-style: none;
                    counter-increment: numeric-counter;
                    position: relative;
                    padding-left: 35px;
                    margin: 0;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    color: var(--paletteColor1);
                    line-height: 16px;
                    z-index: 1;
                    &::before {
                        content: counter(numeric-counter);
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        width: 27px;
                        height: 27px;
                        display: inline-block;
                        border: solid var(--paletteColor1) 2px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 16px;
                        font-weight: 600;
                        background-color: #fff;
                    }
                    &::after {
                        content: '';
                        width: 2px;
                        height: 100%;
                        background-color: var(--paletteColor1);
                        left: 12px;
                        top: 30px;
                        position: absolute;
                        z-index: -1;
                    }
                    &:last-of-type:after {
                        display: none;
                    }
                }
            }
        }
    }
    .tickets {
        &__calendar {
            .datepicker-inline {
                width: 100%;
            }
        }
        &__search {
            margin-bottom: 40px;
            display: flex;
            flex-wrap: wrap;
            gap: 40px;
            // #datepicker {
            //     width: auto;
            //     height: 46px;
            // }
            #clear {
                // display: none;
            }
            #next {
                // display: none;
            }
            #prev {
                // display: none;
            }
            #datepicker {
                width: auto;
                height: unset;
                .datepicker {
                    th, td {
                        padding: 5px;
                    }
                    table tr td.day:not(.disabled) {
                        z-index: 1;
                        &:after {
                            display: block;
                        }
                    }
                    table tr td.day {
                        border-radius: 8px;
                        position: relative;
                        font-size: 14px;
                        padding: 5px 0;
                        border: 3px solid var(--bodyBg);
                        width: 40px;
                        background-color: unset;
                        @include mq($until: 370px) {
                            width: unset;
                        }
                        &:after {
                            content: '';
                            position: absolute;
                            background-color: var(--buttonBgInitial);
                            border-radius: 5px;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            z-index: -1;
                            transition: ease 0.3s all;
                            display: none;
                        }
                        &:hover:after, &.active:after {
                            background-color: var(--buttonBgHover);
                            display: block;
                        }
                        &.disabled {
                            &:hover:after {
                                background-color: unset;
                            }
                        }
                        &.new:not(.disabled), &.old:not(.disabled) {
                            color: #fff;
                            &:after {
                                opacity: 0.3;
                            }
                        }
                    }
                    &-days {
                        thead {
                            background: var(--paletteColor1);
                            th.dow {
                                background: var(--paletteColor6);
                                color: var(--txt);
                                font-size: 14px;
                                text-transform: uppercase;
                            }
                        }
                    }
                    &-inline {
                        width: 100%;
                        border: 1px solid var(--paletteColor5);
                        padding: 10px;
                        @include mq($until: 370px) {
                            padding: 0;
                        }
                    }
                }
            }
        }
        &__legend {
            &-single {
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 20px;
                svg {
                    width: 40px;
                    min-width: 40px;
                    height: 40px;
                    fill: var(--icon);
                    path {
                        fill: var(--icon);
                    }
                }
            }
        }
        &__header {
            &-upcoming {

            }
            &-day {
                display: none;
            }
            span {
                font-size: rem($header-size / 1.3);
                margin-bottom: $header-margin / 1.3;
                color: var(--h2, var(--headings, $header-color));
            }
        }
        &__list {
            &-single {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 0;
                border-bottom: 1px solid var(--paletteColor5);
                column-gap: 20px;
                row-gap: 10px;
                @include mq($until: md) {
                    display: grid;
                    grid-template-columns: 50% 50%;
                    & > div:first-of-type {
                        grid-column: 1 / -1;
                    }
                }
                @include mq($until: 620px) {
                    grid-template-columns: 100%;
                }
                h2 {
                    margin-bottom: 10px;
                    font-size: rem(20px);
                }
                span {
                    text-transform: capitalize;
                }
                .prices {
                    display: flex;
                    gap: 10px;
                    flex-grow: 1;
                    justify-content: flex-end;
                    @include mq($until: md) {
                        justify-content: flex-start;
                    }
                    @include mq($until: 620px) {
                        flex-wrap: wrap;
                    }
                    &--single {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        svg {
                            width: 40px;
                            height: 40px;
                            fill: var(--icon);
                        }
                        .price {
                            font-weight: 400;
                            span {
                                font-weight: 400;
                                font-size: 13px;
                            }
                        }
                    }
                }
                .buttons {
                    display: flex;
                    column-gap: 10px;
                    row-gap: 10px;
                    justify-content: flex-end;
                    @include mq($until: 620px) {
                        justify-content: flex-start;
                        flex-wrap: wrap;
                    }
                }
            }
        }
        &__info {
            display: none;
        }
    }

}
